import { Observable } from 'rxjs';

// Define an interface for the payload within the data
export interface SubscriptionPayload {
  status: string;
}

// Define an interface for the data object
export interface SubscriptionData {
  eventType: string;
  id: string;
  subscriptionId: string;
  payload: SubscriptionPayload;
}

// Define an interface for the deviceRecipients object
export interface SubscriptionDeviceRecipients {
  alertType: string;
  headers: Record<string, any>; // Assuming headers can be any key-value pair
  deviceUrl: string;
}

// Define the main interface for the entire JSON structure
export interface SubscriptionEventResponse {
  data: SubscriptionData;
  deviceRecipients: SubscriptionDeviceRecipients;
  type: string;
}

export interface ISubscriptionEventData {
  eventType: string;
  id: string;
  subscriptionId: string;
  status: string;
}

export const INITIAL_SUBSCRIPTION_DATA = {
  eventType: '',
  id: '',
  subscriptionId: '',
  status: '',
}

export const INITIAL_PHONE_SERVICES_DATA = {
  eventType: '',
  id: '',
  subscriptionId: '',
  status: '',
}

export const INITIAL_ERROR_SUBSCRIPTION_DATA = {
  eventType: '',
  id: '',
  subscriptionId: '',
  errorCode: '',
  errorDescription: ''
}

export const INITIAL_INTEGRATION_SETTINGS_DATA = {
  eventType: '',
  id: '',
  subscriptionId: '',
 isCallingDockEnabled: ''
}

// Define the Payload interface for the integration.error event
export interface IntegrationErrorPayload {
  error: string; // Error code as a string
  description: string; // Error description
}

// Define the Data interface for the integration.error event
export interface IntegrationErrorData {
  eventType: "integration.error"; // Specific event type
  id: string; // Unique identifier for the event
  payload: IntegrationErrorPayload; // Error details
  subscriptionId?: string;
}

// Define the DeviceRecipient interface for the integration.error event
export interface IntegrationErrorDeviceRecipient {
  alertType: string; // Type of alert, e.g., "none"
  headers: Record<string, string>; // Headers as key-value pairs
  deviceUrl: string; // URL pointing to the device
}

// Define the main interface for the integration.error publish request
export interface IntegrationErrorResponse {
  data: IntegrationErrorData; // Event data
  deviceRecipients: IntegrationErrorDeviceRecipient[]; // List of device recipients
  type: string; // Type of request, e.g., "publishRequest"
}

export interface IIntegrationErrorEventData {
  eventType: string;
  id: string;
  subscriptionId?: string;
  errorCode: string;
  errorDescription: string;

}

// Define constants for event types
export const enum DATA_PIPE_LINE_EVENTS {
  INTEGRATION_SUBSCRIPTION = 'event:integration.subscription',
  INTEGRATION_PHONE_SERVICES = 'event:integration.phoneservices',
  INTEGRATION_SETTINGS = 'event:integration.settings',
  INTEGRATION_E911 = 'event:integration.e911',
  INTEGRATION_ERROR = 'event:integration.error',
};

export const enum DISPATCH_STORAGE_EVENTS {
  STORAGE_SUBSCRIPTION_DATA = 'storage_subscription_data',
  STORAGE_PHONE_SERVICES_DATA = 'storage_phone_services_data',
  STORAGE_INTEGRATION_SETTINGS_DATA = 'storage_integration_settings_data',
  STORAGE_INTEGRATION_E911_DATA = 'storage_integration_e911_data',
  STORAGE_INTEGRATION_ERROR = 'storage_integration_error'
}

export const enum SUBSCRIPTION_STATUS {
  TERMINATED = 'terminated',
  ACTIVE = 'active'
}

export const enum PHONE_SERVICES_STATUS_LABEL {
CONNECTED = 'connected',
DISCONNECTED = 'disconnected',
CONNECTING = 'connecting'
}
export interface IntegrationPhoneServicesLine {
  GUID: string;
  index: number;
  label: string;
  name: string;
  number: string;
  status: string;
}

export interface IntegrationPhoneServicesDetails {
  actions: any[];
  message: string;
}

export interface IntegrationPhoneServicesPayload {
  details: IntegrationPhoneServicesDetails;
  lines: IntegrationPhoneServicesLine[];
  status: string;
}

export interface IntegrationPhoneServicesData {
  eventType: string;
  id: string;
  payload: IntegrationPhoneServicesPayload;
  subscriptionId: string;
}

export interface IntegrationPhoneServicesResponse {
  id: string;
  data: IntegrationPhoneServicesData;
  timestamp: number;
  trackingId: string;
  alertType: string;
  headers: Record<string, any>;
  sequenceNumber: number;
  filterMessage: boolean;
  wsWriteTimestamp: number;
}

export interface IPhoneServicesEventData {
  eventType: string;
  id: string;
  subscriptionId: string;
  status: string;
}

export interface IntegrationSettingsResponse {
  id: string;
  data: IntegrationSettingsData;
  timestamp: number;
  trackingId: string;
  alertType: string;
  headers: Record<string, string>;
  sequenceNumber: number;
  filterMessage: boolean;
  wsWriteTimestamp: number;
}

export interface IntegrationSettingsData {
  eventType: string;
  id: string;
  payload: IntegrationSettingsPayload;
  subscriptionId: string;
}

export interface IntegrationSettingsPayload {
  scope: string;
  settings: IntegrationSettingsSettings;
}

export interface IntegrationSettingsSettings {
  "calling.callingdock.enabled": string;
}

export interface IIntegrationSettingsEventData {
  eventType: string;
  id: string;
  subscriptionId: string;
 isCallingDockEnabled: string;
}

export interface IntegrationE911Payload {
  emergencyLocationValidated: boolean;
  featureEnabled: boolean;
}
export interface IntegrationE911Data {
  eventType: string; // e.g., "integration.e911"
  id: string; // UUID format
  payload: IntegrationE911Payload;
  subscriptionId: string; // UUID format
}
export interface IntegrationE911Response {
  id: string; // UUID format
  data: IntegrationE911Data;
  timestamp: number; // Unix timestamp in milliseconds
  trackingId: string; // e.g., "CLIENT_..."
  alertType: string; // e.g., "none"
  headers: Record<string, unknown>; // Can contain key-value pairs of any type
  sequenceNumber: number; // Sequence number
  filterMessage: boolean; // Whether the message is filtered
  wsWriteTimestamp: number; // Unix timestamp in milliseconds
}



export interface IEventAdapter {
  refreshSubscriptionStatus(): void;
  getSubscriptionData(): Observable<ISubscriptionEventData>;
  refreshPhoneServicesSubscriptionStatus(): void;
  getPhoneServicesData(): Observable<IPhoneServicesEventData>;
  // refreshIntegrationSettingssubscription(): void;
  // getIntegrationSettingsData(): Observable<IIntegrationSettingsEventData>
  refreshErrorSubscriptionStatus(): void;
  getErrorEventData(): Observable<IIntegrationErrorEventData>
}