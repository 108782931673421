import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from "../../../logger/src/LocalStorage";
import { v4 as uuid } from 'uuid';
import { formatError, Logger } from "../../../logger/src/Logger";
import {
    SubscriptionEventResponse,
    DATA_PIPE_LINE_EVENTS,
    IntegrationErrorResponse,
    IntegrationPhoneServicesResponse,
    IntegrationSettingsResponse,
    DISPATCH_STORAGE_EVENTS,
    IntegrationE911Response
} from '../../../adapter-interfaces/src';
import { EventType, eventEmitter } from "../utils/eventEmitter";

let makeIntegrationSubscriptionRegister = true;
let makeIntegrationErrorRegister = true;
let makeIntegrationPhoneServicesRegister = true;
let makeIntegrationSettingsRegister = true;
let makeIntegrationE911Register = true;
const MOCK_INTEGRATION_SETTINGS_DATA = {
    "id": "e21f0d78-0448-4255-a978-ea7c1f7f9c70",
    "data": {
        "eventType": "integration.settings",
        "id": "308bba63-2f56-4db8-8f95-c5630bed8a00",
        "payload": {
            "scope": "full",
            "settings": {
                "calling.callingdock.enabled": "true"
            }
        },
        "subscriptionId": "ba19d486-6e99-41ef-a739-684a5aeb8ae3"
    },
    "timestamp": 1733977789275,
    "trackingId": "CLIENT_69972b13-8253-4a2b-859a-f1fb5cbf6a14",
    "alertType": "none",
    "headers": {},
    "sequenceNumber": 4,
    "filterMessage": false,
    "wsWriteTimestamp": 1733977789275
}
const MOCK_E911_DATA = {
    "id": "42714560-bd44-41c9-99d1-969fe3bfd62b",
    "data": {
        "eventType": "integration.e911",
        "id": "f1ca2e7e-f3bf-4e0b-9ae4-0fa9b15997cb",
        "payload": {
            "emergencyLocationValidated": true,
            "featureEnabled": true
        },
        "subscriptionId": "b1519880-43db-4de6-9b7c-359c63489775"
    },
    "timestamp": 1734667099559,
    "trackingId": "CLIENT_06adfcce-df28-45bc-be09-451cfccf25f8",
    "alertType": "none",
    "headers": {},
    "sequenceNumber": 5,
    "filterMessage": false,
    "wsWriteTimestamp": 1734667099559
}

const dispatchScopedEvent = (key: string) => {
    const event = new CustomEvent(`localstorage:${key}`);
    window.dispatchEvent(event);
};

// Async function to initialize the data pipeline subscription
async function initDataPipelineSubscription() {
    try {
        const initialSubscriptionFTCheck = await window.webex.internal.feature.getFeature('developer', 'desktop-msteams-integration-data-channel-spark-506019');
        const devSubscriptionFTCHECK =  await window.webex.internal.feature.getFeature('developer', 'desktop-msteams-integration-data-channel-spark-506019-dev');
        if (initialSubscriptionFTCheck || devSubscriptionFTCHECK) {
            Logger.info("Data pipeline feature toggle check is enabled")
            const webex_crossLaunchToken = getLocalStorageItem<string>("webex_crossLaunchToken");

            // Proceed only if the token is available
            if (webex_crossLaunchToken) {
                Logger.info(`WebexPhoneService.initDataPipelineSubscription(): data pipeline cross launching`);
                // Generate UUIDs for subscriptionId and requestId
                const subscriptionId = uuid();
                const requestId = uuid();
                // Retrieve the device ID using window.webex.internal.device.getId()
                const deviceUrl = window.webex.internal.device.getId();
                Logger.info(`WebexPhoneService.initDataPipelineSubscription() deviceUrl: ${deviceUrl}`);

                // Construct the Webex cross-launch URL with the generated values
                const webexUrl = `webex://integration?cmd=subscribe&events=phoneservices,settings,e911&subscriptionId=${subscriptionId}&requestId=${requestId}&deviceUrl=${deviceUrl}&source=MSTeams&jws=${webex_crossLaunchToken}`;
                const callUrl = new URL(webexUrl);

                window.open(callUrl.href);
            }
        } else {
            Logger.info("Data pipeline feature toggle check is not enabled")
        }
    } catch (error) {
        Logger.error(
            `WebexPhoneService.initDataPipelineSubscription(): Error initializing data pipeline subscription:: '${formatError(
                error
            )}'.`
        );
        throw error;
    }
}


export async function getDataPipelineSubscription(): Promise<any> {
    if (window?.isWebexInitalized) {
        return initDataPipelineSubscription();
    } else {
        await new Promise((f) => setTimeout(f, 1000));
        return getDataPipelineSubscription();
    }
}

async function listenSubscriptionStatus(): Promise<SubscriptionEventResponse> {
    return new Promise<SubscriptionEventResponse>((resolve) => {
        if (makeIntegrationSubscriptionRegister) {
            const eventHandler = (sessionData: SubscriptionEventResponse) => {
                Logger.info(
                    `User subscribed to the event '${sessionData.data.eventType}'`
                );
                eventEmitter.dispatch(EventType.INTEGRATION_SUBSCRIPTION_EVENT_TYPE, "integrationSubscription");
                setLocalStorageItem(DISPATCH_STORAGE_EVENTS.STORAGE_SUBSCRIPTION_DATA, sessionData);
                dispatchScopedEvent("subscription");
                resolve(sessionData); // Resolve the promise with the event payload
            };

            window.webex.internal.mercury.on(
                DATA_PIPE_LINE_EVENTS.INTEGRATION_SUBSCRIPTION,
                eventHandler
            );

            // window.webex.internal.mercury.on(DATA_PIPE_LINE_EVENTS.INTEGRATION_PHONE_SERVICES, (event: any) => {
            //     console.log('Phone services event from webex', event)
            //     eventEmitter.dispatch(EventType.INTEGRATION_SUBSCRIPTION_EVENT_TYPE, "integrationSubscription");
            //   });

            //   window.webex.internal.mercury.on(DATA_PIPE_LINE_EVENTS.INTEGRATION_SETTINGS, (event: any) => {
            //     console.log('Phone settings event from webex', event)
            //     eventEmitter.dispatch(EventType.INTEGRATION_SUBSCRIPTION_EVENT_TYPE, "integrationSubscription");
            //   });

            //   window.webex.internal.mercury.on(DATA_PIPE_LINE_EVENTS.INTEGRATION_E911, (event: any) => {
            //     console.log('E911 event from webex', event)
            //     eventEmitter.dispatch(EventType.INTEGRATION_SUBSCRIPTION_EVENT_TYPE, "integrationSubscription");
            //   });

            //   window.webex.internal.mercury.on(DATA_PIPE_LINE_EVENTS.INTEGRATION_ERROR, (event: any) => {
            //     console.log('Error event from webex', event)
            //     eventEmitter.dispatch(EventType.INTEGRATION_SUBSCRIPTION_EVENT_TYPE, "integrationSubscription");
            //   });

              makeIntegrationSubscriptionRegister = false;
        }
    });
}

export async function getSubscriptionStatus(): Promise<SubscriptionEventResponse> {
    if (window?.isWebexInitalized) {
        return listenSubscriptionStatus();
    } else {
        await new Promise((f) => setTimeout(f, 1000));
        return getSubscriptionStatus();
    }
}

async function listenPhoneServicesStatus(): Promise<IntegrationPhoneServicesResponse> {
    return new Promise<IntegrationPhoneServicesResponse>((resolve) => {
        removeLocalStorageItem(DISPATCH_STORAGE_EVENTS.STORAGE_PHONE_SERVICES_DATA);
        if (makeIntegrationPhoneServicesRegister) {
            const eventHandler = (sessionData: IntegrationPhoneServicesResponse) => {
                Logger.info(
                    `User subscribed to the Phone services event event '${sessionData.data.eventType}'`
                );
                eventEmitter.dispatch(EventType.INTEGRATION_PHONE_SERVICES_EVENT_TYPE, "integrationPhoneServices");
                setLocalStorageItem(DISPATCH_STORAGE_EVENTS.STORAGE_PHONE_SERVICES_DATA, sessionData);
                dispatchScopedEvent("phoneServices");
                resolve(sessionData); // Resolve the promise with the event payload
            };

            window.webex.internal.mercury.on(
                DATA_PIPE_LINE_EVENTS.INTEGRATION_PHONE_SERVICES,
                eventHandler
            );

            makeIntegrationPhoneServicesRegister = false;
        }
    });
}

export async function getPhoneServicesData(): Promise<IntegrationPhoneServicesResponse> {
    if (window?.isWebexInitalized) {
        return listenPhoneServicesStatus();
    } else {
        await new Promise((f) => setTimeout(f, 1000));
        return getPhoneServicesData();
    }
}

//Integration.settings service

async function listenIntegrationSettingsStatus(): Promise<IntegrationSettingsResponse> {
    return new Promise<IntegrationSettingsResponse>((resolve) => {
        removeLocalStorageItem(DISPATCH_STORAGE_EVENTS.STORAGE_INTEGRATION_SETTINGS_DATA);
        if (makeIntegrationSettingsRegister) {
            const eventHandler = (sessionData: IntegrationSettingsResponse) => {
                Logger.info(
                    `User subscribed to the Integration Settings event '${sessionData.data.eventType}'`
                );
                eventEmitter.dispatch(EventType.INTEGRATION_SETTINGS_EVENT_TYPE, "integrationSettings");
                //TO DO CHANGE MOCK TO REAL DATA
                setLocalStorageItem(DISPATCH_STORAGE_EVENTS.STORAGE_INTEGRATION_SETTINGS_DATA, sessionData);
                dispatchScopedEvent("integrationSettings");
                resolve(sessionData); // Resolve the promise with the event payload
            };

            window.webex.internal.mercury.on(
                DATA_PIPE_LINE_EVENTS.INTEGRATION_SETTINGS,
                eventHandler
            );

            makeIntegrationSettingsRegister = false;
        }
    });
}

export async function getIntegrationSettingsData(): Promise<IntegrationSettingsResponse> {
    if (window?.isWebexInitalized) {
        return listenIntegrationSettingsStatus();
    } else {
        await new Promise((f) => setTimeout(f, 1000));
        return getIntegrationSettingsData();
    }
}

//Integration.E911 service

async function listenIntegrationE911Status(): Promise<IntegrationE911Response> {
    return new Promise<IntegrationE911Response>((resolve) => {
        removeLocalStorageItem(DISPATCH_STORAGE_EVENTS.STORAGE_INTEGRATION_E911_DATA);
        if (makeIntegrationE911Register) {
            const eventHandler = (sessionData: IntegrationE911Response) => {
                Logger.info(
                    `User subscribed to the Integration e911 event '${sessionData.data.eventType}'`
                );
                eventEmitter.dispatch(EventType.INTEGRATION_E911_EVENT_TYPE, "integrationE911");
                //TO DO CHANGE MOCK TO REAL DATA
                setLocalStorageItem(DISPATCH_STORAGE_EVENTS.STORAGE_INTEGRATION_E911_DATA, sessionData);
                dispatchScopedEvent("integrationE911");
                resolve(sessionData); // Resolve the promise with the event payload
            };

            window.webex.internal.mercury.on(
                DATA_PIPE_LINE_EVENTS.INTEGRATION_E911,
                eventHandler
            );

            makeIntegrationE911Register = false;
        }
    });
}

export async function getIntegrationE911Data(): Promise<IntegrationE911Response> {
    if (window?.isWebexInitalized) {
        return listenIntegrationE911Status();
    } else {
        await new Promise((f) => setTimeout(f, 1000));
        return getIntegrationE911Data();
    }
}

//error subscription event

async function listenErrorEventResponse(): Promise<IntegrationErrorResponse> {
    return new Promise<IntegrationErrorResponse>((resolve) => {
        removeLocalStorageItem(DISPATCH_STORAGE_EVENTS.STORAGE_INTEGRATION_ERROR);
        if (makeIntegrationErrorRegister) {
            const eventHandler = (sessionData: IntegrationErrorResponse) => {
                Logger.info(
                    `User recieved the error event '${sessionData.data.eventType}'`
                );
                eventEmitter.dispatch(EventType.INTEGRATION_ERROR_EVENT_TYPE, "integrationError");
                setLocalStorageItem(DISPATCH_STORAGE_EVENTS.STORAGE_INTEGRATION_ERROR, sessionData);
                dispatchScopedEvent("integrationError");
                resolve(sessionData); // Resolve the promise with the event payload
            };

            window.webex.internal.mercury.on(
                DATA_PIPE_LINE_EVENTS.INTEGRATION_ERROR,
                eventHandler
            );

            makeIntegrationErrorRegister = false;
        }
    });
}

export async function getErrorEventData(): Promise<IntegrationErrorResponse> {
    if (window?.isWebexInitalized) {
        return listenErrorEventResponse();
    } else {
        await new Promise((f) => setTimeout(f, 1000));
        return getErrorEventData();
    }
}
