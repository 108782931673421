//un comment once this added to ccomponent-adapter-interfaces
import {
  Logger,
  formatError,
  getLocalStorageItem,
  getMSTeamsContext,
} from "../../../../packages/logger/src";
import {
  APP,
  CALLING_BACKEND,
  IMetricsData,
  INTEGRATION,
  IUserData,
  METRIC
} from "../../../adapter-interfaces/src/IMetricsAdapter";
import { msTeamsIntegration, appVersion } from "../utils/MetricUtils";

export async function submitMetrics(
  metricObject: IUserData,
): Promise<any> {
  try {
    if(!(window?.isWebexInitalized)) {
      await new Promise(f => setTimeout(f, 1000));
      return submitMetrics(metricObject);
    }
    const orgName = await (
      window as any
    )?.webex?.internal?.device?.orgName;
    const userProperties = await constructPayload(metricObject, orgName);
    const eventPayload = {
      metricInput: { userContext: userProperties},
      isTestClient: false,
      client_timestamp: metricObject.client_timestamp
        ? metricObject.client_timestamp
        : msTeamsIntegration,
      trackingId: metricObject.trackingId
        ? metricObject.trackingId
        : msTeamsIntegration,
      key: metricObject.key ? metricObject.key : msTeamsIntegration,
      value: metricObject.value ? metricObject.value : msTeamsIntegration,
    };
    const Metrics = { type: [METRIC.BUSINESS, METRIC.BEHAVIORAL, METRIC.OPERATIONAL], eventPayload: eventPayload, tags: userProperties };
    const response = await (
      window as any
    )?.webex?.internal?.metrics?.submitClientMetrics(
      metricObject.metricEvent,
      Metrics
    );
    return response;
  } catch (error) {
    Logger.error(
      `WebexMetricsService.submiMetrics(): got error: '${formatError(error)}'.`
    );
    throw error;
  }
}

export async function constructPayload(
  metricObject: IUserData, orgName: string
): Promise<IMetricsData> {
  const context = await getMSTeamsContext();
  const payload: IMetricsData = {
    metricEvent: metricObject.metricEvent,
    appName: APP.WEBEXTEAMS,
    appSessionId: context.app.sessionId,
    appVersion: appVersion,
    entityId: context.page.id,
    sessionId: context.app.host.sessionId,
    userObjectId: context.user?.id,
    loginHint: context.user?.loginHint,
    hostClientType: context.app.host.clientType,
    tid: context.user?.tenant?.id,
    integrationName: INTEGRATION.MSTEAMS,
    messageId: metricObject.messageId,
    responseStatus: metricObject.responseStatus,
    callingBackend: getLocalStorageItem<string>(CALLING_BACKEND.WEBEX_USER)
      ? getLocalStorageItem<string>(CALLING_BACKEND.WEBEX_USER)
      : "",
    contentSize: metricObject.contentSize,
    executionTime: metricObject.executionTime,
    presenceServerApiReturnCode: metricObject.presenceServerApiReturnCode
      ? metricObject.presenceServerApiReturnCode
      : undefined,
    presenceServerApiLatency: metricObject.presenceServerApiLatency
      ? metricObject.presenceServerApiLatency
      : "0",
    voiceMailDuration: metricObject.voiceMailDuration
      ? metricObject.voiceMailDuration
      : "0",
    voicemailListLatency: metricObject.voicemailListLatency
      ? metricObject.voicemailListLatency
      : "0",
    recentCallsListLatency: metricObject.recentCallsListLatency
      ? metricObject.recentCallsListLatency
      : "0",
    callForwardSettingsLatency: metricObject.callForwardSettingsLatency
      ? metricObject.callForwardSettingsLatency
      : "0",
    voicemailSettingsLatency: metricObject.voicemailSettingsLatency
      ? metricObject.voicemailSettingsLatency
      : "0",
    callForwardSettingsApiReturnCode: metricObject.callForwardSettingsApiReturnCode
      ? metricObject.callForwardSettingsApiReturnCode
      : undefined,
    voicemailSettingsApiReturnCode: metricObject.voicemailSettingsApiReturnCode
      ? metricObject.voicemailSettingsApiReturnCode
      : undefined,
    voiceMailContentErrorResponseCode:
      metricObject.voiceMailContentErrorResponseCode
        ? metricObject.voiceMailContentErrorResponseCode
        : undefined,
    callMadeSection: metricObject.callMadeSection
    ? metricObject.callMadeSection
    : undefined,
    activeTab: metricObject.activeTab
    ? metricObject.activeTab
    : undefined,
    callType: metricObject.callType
    ? metricObject.callType
    : undefined,
    orgName: orgName ? orgName : undefined,
    key: metricObject.key ? metricObject.key : msTeamsIntegration,
    downloadRedirectSection: metricObject.downloadRedirectSection
    ? metricObject.downloadRedirectSection
    : undefined,
    phoneServiceStatus: metricObject.phoneServiceStatus ?  metricObject.phoneServiceStatus : undefined,
    callingDockStatus: metricObject.callingDockStatus ? metricObject.callingDockStatus : undefined,
    e911Status: metricObject.e911Status ? metricObject.e911Status : undefined,
    dataTransferSubscriptionStatus: metricObject.dataTransferSubscriptionStatus ? metricObject.dataTransferSubscriptionStatus : undefined,
    dataTransferErrorStatus: metricObject.dataTransferErrorStatus ? metricObject.dataTransferErrorStatus : undefined
  };
  return payload;
}

//delete once this added to ccomponent-adapter-interfaces
// export interface IMetricsData {
//   appName: string;
//   appSessionId: string;
//   entityId: string;
//   sessionId: string;
//   userObjectId?: string;
//   metricEvent: string;
//   loginHint?: string;
//   hostClientType: string;
//   tid?: string;
//   numberOfSpeedDials?: string;
//   integrationName: string;
//   messageId?: string;
//   responseStatus?: string;
//   callingBackend: string;
//   contentSize?: string;
//   executionTime?: string;
// }
// export interface IUserData {
//   metricEvent: string;
//   messageId?: string;
//   integrationName: INTEGRATION;
//   responseStatus?: string;
//   contentSize?: string;
//   executionTime?: string;
//   client_timestamp?: string;
//   trackingId?: string;
//   key?: string;
//   value?: string;
// }

// export enum METRIC {
//   BUSINESS = 'business',
// }

// export enum INTEGRATION {
//   MSTEAMS = 'msteams',
// }

// export enum APP {
//   WEBEXTEAMS = 'webexteams',
// }

// export enum CALLING_BACKEND {
//   WEBEX_USER = 'webex_userCallingBackend',
// }

// export interface IMetricsAdaptor {}
