import { ButtonCircle, ButtonPill, Flex, Text } from "@momentum-ui/react-collaboration";
import { useTranslation } from "react-i18next";
import { GenericModal } from '../../packages/widgets/src/components/GenericModal/GenericModal';
import "./CallingWidgetOffModal.scss"
import { setLocalStorageItem } from "../../packages/logger/src";
import { FIRST_TIME_UX_FOR_CALLING_DOCK_TOGGLE_OFF } from "../../packages/widgets/src/utils/MetricUtils";

interface ICallingWidgetOffModalProps {
    isOpen: boolean,
    onCancel: () => void,
    handleIntegrationSettingsStorageEventOnClose: () => void;
    disableCallingDockActionState: () => void;
}


export const CallingWidgetOffModal: React.FC<ICallingWidgetOffModalProps> = ({ isOpen, onCancel, handleIntegrationSettingsStorageEventOnClose, disableCallingDockActionState }) => {
    const { t } = useTranslation();

    const handleDisableCallingDockAction = () => {
        setLocalStorageItem(FIRST_TIME_UX_FOR_CALLING_DOCK_TOGGLE_OFF, true);
        handleIntegrationSettingsStorageEventOnClose && handleIntegrationSettingsStorageEventOnClose();
        disableCallingDockActionState();
        onCancel();
    }

    const handleOnClose = () => {
        setLocalStorageItem(FIRST_TIME_UX_FOR_CALLING_DOCK_TOGGLE_OFF, true);
        handleIntegrationSettingsStorageEventOnClose && handleIntegrationSettingsStorageEventOnClose();
        onCancel();
    }


    return (
        <GenericModal
            isOpen={isOpen}
            isRound
            className="calling-widget__modal calling-widget__modal--off"
        >
            <Flex direction="column" className="calling-widget__content">
                <Flex className="calling-widget__close-icon-container" justifyContent="flex-end">
                <ButtonCircle className='cancel-button' ghost onPress={handleOnClose}>
                    <svg width="16" height="16" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.4139 15.9997L25.7069 7.70669C25.7997 7.61384 25.8734 7.50361 25.9236 7.3823C25.9739 7.26098 25.9997 7.13096 25.9997 6.99965C25.9997 6.86835 25.9738 6.73833 25.9236 6.61701C25.8733 6.4957 25.7997 6.38548 25.7068 6.29263C25.614 6.19978 25.5038 6.12614 25.3824 6.07589C25.2611 6.02564 25.1311 5.99978 24.9998 5.99978C24.8685 5.99979 24.7385 6.02565 24.6172 6.0759C24.4958 6.12616 24.3856 6.19981 24.2928 6.29266L15.9998 14.5857L7.70677 6.29266C7.61412 6.19895 7.50385 6.12447 7.38231 6.07352C7.26077 6.02257 7.13035 5.99615 6.99857 5.99578C6.86678 5.9954 6.73622 6.02109 6.61439 6.07135C6.49257 6.12161 6.38188 6.19546 6.28869 6.28865C6.19551 6.38184 6.12166 6.49253 6.07141 6.61436C6.02115 6.73619 5.99547 6.86675 5.99585 6.99854C5.99623 7.13033 6.02266 7.26074 6.07362 7.38228C6.12457 7.50381 6.19905 7.61408 6.29277 7.70673L14.5858 15.9997L6.29277 24.2927C6.19992 24.3856 6.12627 24.4958 6.07602 24.6171C6.02577 24.7384 5.99991 24.8685 5.99991 24.9998C5.99991 25.1311 6.02577 25.2611 6.07602 25.3824C6.12627 25.5037 6.19992 25.6139 6.29277 25.7068C6.38562 25.7996 6.49584 25.8733 6.61716 25.9235C6.73847 25.9738 6.86849 25.9997 6.9998 25.9997C7.13111 25.9997 7.26113 25.9738 7.38244 25.9235C7.50376 25.8733 7.61398 25.7996 7.70683 25.7068L15.9998 17.4138L24.2928 25.7068C24.4803 25.8943 24.7347 25.9997 24.9999 25.9997C25.2651 25.9997 25.5194 25.8943 25.7069 25.7068C25.8944 25.5193 25.9998 25.2649 25.9998 24.9998C25.9998 24.7346 25.8944 24.4802 25.7069 24.2927L17.4139 15.9997Z" fill="var(--mds-color-theme-text-primary-normal)" />
                    </svg>
                </ButtonCircle>
                </Flex>
                <Text type="header-primary" className="calling-widget__title">{t("callingDock.turnOffLabel")}</Text>
                <Text type="body-primary" className="calling-widget__warning">{t("callingDock.loseAccessLabel")}</Text>
                <Flex justifyContent="flex-end" className="calling-widget__button-container">
                    <ButtonPill size={32} onPress={handleDisableCallingDockAction} aria-label={t("label.done")}>{t("label.done")}</ButtonPill>
                </Flex>
            </Flex>
        </GenericModal>

    )
}
