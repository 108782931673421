export const msTeamsIntegration = "webexCallingMsTeamsIntegration";
export const voicemail = "voicemail";
export const voicemailLabel = "Voicemail";
export const dialpadLabel = 'Dialpad';
export const searchpadLabel = 'searchpad';
export const mobileChatExtension = 'Mobile-Chat-Extension';
export const channelLabel = 'channel';
export const channelMembersSearch = 'Search channel members';
export const speedDialLabel = 'Speed dial';
export const callSettingsClickedMetric = "call-settings-clicked-metric";
export const MobileOpenWebexClickedMetric = "mobile-open-webex-clicked-metric";
export const MobileDialpad = 'Mobile-Dialpad';
export const presenceServerApiReturnCodeMetric =
  "presence-server-api-return-code-metric";
export const presenceServiceFailureMetric = "presence-service-failure-metric";
export const googlePlayStoreMetric = "googlePlayStore-metric";
export const appStoreMetric = "appStore-metric";
export const presenceServerApiLatencyMetric =
  "presence-server-api-latency-metric";
export const userVisitedMetric = "userVisited-metric"
export const appVersion = '2.1';
export const addSpeedDialMetric = "add-speed-dial-metric";
export const removeSpeedDialMetric = "remove-speed-dial-metric";
export const editSpeedDialMetric = "edit-speed-dial-metric";
export const moveSpeedDialMetric = "move-speed-dial-metric";
export const voiceMailDurationMetric = "voiceMailDuration-metric";
export const serverSideErrorWhileFetchingVMContentMetric =
  "serverSideErrorWhileFetchingVMContent-metric";
export const recentCallsListLatencyMetric = "recentCallsListLatency-metric";
export const voicemailListLatencyMetric = "voicemailListLatency-metric";
export const voicemailDeletedMetric = "voicemail-deleted-metric";
export const signOutMetric = 'signOut-metric';
export const videoCall = 'videoCall';
export const audioCall = 'audioCall';
export const Error_String = 'error';
export const Error_Subcode = 'error_subcode';
export const Access_Denied = 'access_denied';
export const Require_Approval = 'require_approval';
export const Need_Admin_Approval = 'need_admin_approval';
export const Error_Description = 'error_description';
export const Cancel = 'cancel';
export const Error_Code_AADSTS65001 = 'AADSTS65001'
export const crossLaunchCallMetric = 'crossLaunchCall-metric'
export const voicemailPlayedFailureMETRIC = 'voicemail-played-failure-metric';
export const voicemailPlayedSuccessMETRIC = 'voicemail-played-success-metric';
export const activeTabBarMetric = 'activeTabBar-metric';
export const businessNumberSuccessMetric = 'businessNumberSuccess-metric';
export const businessNumberFailureMetric = 'businessNumberFailure-metric'
export const voicemailServerSideErrorResponseStatus = "Error";
export const voicemailServerSideErrorResponseStatusCode = "5xx";
export const SPEED_DIAL_NOT_UPTO_DATE = 'speedDialNotUptoDate';
export const SD_ERROR_LIST_MSG = 'sdErrorListMsg';
export const FIRST_TIME_DOWNLOAD = 'firstTimeDownload';
export const FIRST_TIME_UX_FOR_CALLING_DOCK = 'firstTimeUXForCallingDock';
export const FIRST_TIME_UX_FOR_CALLING_DOCK_TOGGLE_OFF = 'firstTimeUXForCallingDockToggleOff';
export const PHONE_SERVICES_METRIC_EVENT = "phone-service-metrics";
export const CALLING_DOCK_METRIC_EVENT = 'calling-dock-metrics';
export const E911_METRIC_EVENT = 'e911-metrics';
export const DATA_TRANSFER_SUBSCRIPTION_METRICS_EVENT = 'data-transfer-subscription-metrics';
export const DATA_TRANSFER_ERROR_METRICS_EVENT = 'data-transfer-error-metrics';
export const SPEED_DIAL_CACHED_DATE = 'speedDialCachedData';
export enum SD_SUCCESS_NOTICATION {
  SD_SUCCESS_ADD = 'speed_dial_successfully_added',
  SD_SUCCESS_DELETE = 'speed_dial_successfully_deleted',
  SD_SUCCESS_EDIT = 'speed_dial_successfully_edited',
  SD_SUCCESS_REORDER = 'speed_dial_successfully_reordered',
}
export enum SD_FAILURE_NOTICATION {
  SD_FAILURE_ADD = 'speed_dial_add_failed',
  SD_FAILURE_DELETE = 'speed_dial_delete_failed',
  SD_FAILURE_EDIT = 'speed_dial_edit_failed',
  SD_FAILURE_REORDER = 'speed_dial_reorder_failed',
}
export enum SD_NOTIFICATION {
  SD_ADDED = 'added',
  SD_DELETED = 'deleted',
  SD_EDITED = 'edited',
  SD_REORDERED = 're-ordered',
}

export enum SD_ERROR_NOTIFICATION {
  SD_CREATE = 'create',
  SD_DELETE = 'delete',
  SD_EDIT = 'edit',
  SD_REORDER = 're-order',
}

export const CALLING_SPEED_DIAL_FOCUS_GROUP = 'callingSpeedDialFocusGroup';

export const NUMBER_PAD_FOCUSED = 'NumberPadFocused';

export const SEARCH_INPUT_FOCUSED = 'SearchInputFocused';

export const STORAGE = 'storage';

export enum WORK_TYPE {
  ITEM_TYPE = 'work',
}

export enum MAKE_A_CALL_LABELS {
  RECENT_CALLS = "Recent calls", 
  VOICEMAIL = "Voicemail", 
  ONE_TO_ONE_CHAT = "1:1 chat",
  CHANNEL = "channel"
}

export enum LOCALIZATION_CODES {
  DE_DE = "de-de"
}

export enum INTERNAL_OPERATIONS {
  SAVE = "*SAVE#"
}

export enum PHONE_SERVICE_STATUS_FOR_METRICS {
  CONNECTED = 'Connected',
  DISCONNECTED = 'Disconnected',
  CONNECTING = 'Connecting'
};

export enum CALLING_DOCK_STATUS_FOR_METRICS {
  NOT_SUPPORTED = 'Not-supported',
  ENABLED = "Enabled",
  DISABLED = "Disabled"
}

export enum E911_STATUS_FOR_METRICS {
  NOT_SUPPORTED = 'Not-supported',
  ADDRESS_NOT_VALIDATED = 'Address-not-validated',
  ADDRESS_VALIDATED = 'Address-validated'
}

export enum DATA_TRANSFER_ERROR_STATUS_FOR_METRICS {
  NOT_SUPPORTED = 'Not-supported',
  INVALID_TOKEN = 'Invalid-token',
  INVALID_SUBSCRIPTION = 'Invalid-subscription'
}