import { WebexSignInPage } from '../../packages/widgets/src/WebexSignInPage';

import { HostClientType } from '@microsoft/teams-js';
import { LoadingSpinner } from '@momentum-ui/react-collaboration';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getMSAccessTokenPromise, getMSTeamsToken, getWebexAccessTokenPromise, getWebexTokens, grantPermission, makeAcallToken } from '../../ms-teams-sdk/service/MsTeamsAuthenticationService';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '../../ms-teams-sdk/util/LocalStorageUtil';
import { getMSTeamsContext } from '../../ms-teams-sdk/util/MsTeamsContextUtil';
import { CH_STORAGE_KEYS, DISPATCH_STORAGE_EVENTS, VM_STORAGE_KEYS, WEBEX_STORAGE_KEYS } from '../../packages/adapter-interfaces/src';
import { downloadLogs, downloadMobileLogs } from '../../packages/logger/src/Logger';
import { initWebex } from "../../packages/widgets/src/services/WebexInstanceService";
import { Error_Code_AADSTS65001, FIRST_TIME_DOWNLOAD, NUMBER_PAD_FOCUSED, SD_ERROR_LIST_MSG, SD_FAILURE_NOTICATION, SEARCH_INPUT_FOCUSED, STORAGE } from "../../packages/widgets/src/utils/MetricUtils";
import { LandingPage } from '../../pages/LandingPage';
import { MobileLandingPage } from '../../pages/MobileLandingPage';
import { DownloadWebex } from '../DownloadWebex/DownloadWebex';
import { RetrySignInImage } from './RetrySignInImage';
import { WebexSignInImage } from './WebexSignInImage';
import { WebexWordMark } from './WebexWordMark';
import { ByCiscoWordMark } from './ByCiscoWordMark';
// import { DownloadWebexDesktop } from '../DownloadWebex/DownloadWebexDesktop';

export interface SignInAccessToken {
  accessToken: string;
  refreshToken: string;
  saveAccessToken: () => {};
}

export interface IWebexWebexSignInProps {
  getAccessToken: () => Promise<SignInAccessToken>;
}

export const WebexSignIn = (() => {
  const [shouldShowMsftSignIn, setShouldShowMsftSignIn] = useState<boolean>(false);
  const [shouldShowWebexSignIn, setShouldShowWebexSignIn] = useState<boolean>(false);

  const { t } = useTranslation();
  const [grantPermissionUrl, setGrantPermissionUrl] = useState<string>('');
  const [showSignInOrRetry, setshowSignInOrRetry] = useState<boolean>(false);
  const [signingIn, setSigningIn] = useState<boolean>(false);
  const [clientType, setClientType] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);
  const fetchClientType = async () => {
    const context = await getMSTeamsContext();
    const clientType = context?.app?.host?.clientType;
    const email = context?.user?.loginHint;
    setEmail(email);
    setClientType(clientType);
  }; 

  const handleGrantPermission = async () => {
    try {
      const res = await grantPermission();
      setGrantPermissionUrl(res)
    }
    catch(error) {
      throw error;
    }
  }

  const getLoginDetails = async () => {
    try {
      const msTokenNotAvailable = await getMSAccessTokenPromise();
      const webexTokenNotAvailable = await getWebexAccessTokenPromise();
      if(msTokenNotAvailable) {
        setShouldShowMsftSignIn(true);
        setShouldShowWebexSignIn(false);
        setIsLoading(false);
      } else if(webexTokenNotAvailable) {
        setShouldShowMsftSignIn(false);
        setShouldShowWebexSignIn(true);
        setIsLoading(false);
      }
      else {
        const webexTokens = await getWebexTokens(false);
        if(!(window?.isWebexInitalized)) {
          initWebex(webexTokens?.access_token as string)
        }
        await makeAcallToken();
        removeLocalStorageItem(DISPATCH_STORAGE_EVENTS.STORAGE_SUBSCRIPTION_DATA);
        removeLocalStorageItem(DISPATCH_STORAGE_EVENTS.STORAGE_INTEGRATION_ERROR);
        removeLocalStorageItem(DISPATCH_STORAGE_EVENTS.STORAGE_INTEGRATION_SETTINGS_DATA);
        removeLocalStorageItem(DISPATCH_STORAGE_EVENTS.STORAGE_PHONE_SERVICES_DATA);
        removeLocalStorageItem(DISPATCH_STORAGE_EVENTS.STORAGE_INTEGRATION_E911_DATA);
        removeLocalStorageItem(VM_STORAGE_KEYS.VM_ERROR_LIST_MSG);
        removeLocalStorageItem(CH_STORAGE_KEYS.CH_ERROR_LIST_MSG);
        removeLocalStorageItem(WEBEX_STORAGE_KEYS.WEBEX_INSTANCE_ERROR);
        removeLocalStorageItem(SD_FAILURE_NOTICATION.SD_FAILURE_ADD);
        removeLocalStorageItem(SD_FAILURE_NOTICATION.SD_FAILURE_DELETE);
        removeLocalStorageItem(SD_FAILURE_NOTICATION.SD_FAILURE_EDIT);
        removeLocalStorageItem(SD_FAILURE_NOTICATION.SD_FAILURE_REORDER);
        removeLocalStorageItem(SD_ERROR_LIST_MSG);
        localStorage.removeItem(NUMBER_PAD_FOCUSED); 
        localStorage.removeItem(SEARCH_INPUT_FOCUSED); 
        removeLocalStorageItem(VM_STORAGE_KEYS.IS_HIDE_VM_TAB);
        window.dispatchEvent(new Event(STORAGE));
        setShouldShowMsftSignIn(false);
        setShouldShowWebexSignIn(false);
      }
    }
    catch(error) {
      throw error;
    }
  }

  const getAdminConsentUrl = () => {
    return grantPermissionUrl;
    // return '#!';
  };

  const msftSignIn = async () => {
    try {
      setIsLoading(true);
      const msTeamsTokens = await getMSTeamsToken(true);
      if(msTeamsTokens) {
        await getLoginDetails();
      }
      setshowSignInOrRetry(false);
      setIsLoading(false);
    } catch (error) {
      if(typeof error === 'string' && error.includes(Error_Code_AADSTS65001)){
        setshowSignInOrRetry(false);
      }
      else {
        setshowSignInOrRetry(true);
      }
      setShouldShowMsftSignIn(true);
      setIsLoading(false);
    }
  };

  const webexSignIn = async (useDifferentAccount?: boolean) => {
    try {
      setSigningIn(true);
      setIsLoading(false);
      const webexTokens = await getWebexTokens(true, useDifferentAccount);
      window.location.reload();
    } catch (e) {
      setSigningIn(false);
      setshowSignInOrRetry(true);
      setIsLoading(false);
    }
  };

  const retryMsftSignIn = async() => {
    msftSignIn();
  };

  const retryWebexSignIn = (useDifferentAccount?: boolean) => {
    webexSignIn(useDifferentAccount);
  };
  
  const downloadAllLogs = (event: any) => {
    event.preventDefault();
    if(clientType === HostClientType.desktop || clientType === HostClientType.web){
      downloadLogs();
    }
    else {
      downloadMobileLogs();
    }
  };

  useEffect(() => {
    msftSignIn();
    fetchClientType();
    handleGrantPermission();
  },[])

  const continueFootNote =
  <div className="navigation-link">
  <p>
    { t('authorizeWebexCalling') } {" "}
    <br/><br/>
    { t('moreInfoWebex') } {" "}
    <a className="webex-more-info" target="_blank" href="https://www.webex.com/cloud-calling.html">
      { t('clickHere') }
    </a>
  </p>
  <div className="wordMark"><WebexWordMark/> <ByCiscoWordMark/></div>
  </div> 
  ;


  const retryFootNote = 
  <div className="navigation-link">
  <p>
    { t('loginUnsuccessfullInfo') }
      <br/><br/>
    { t('errorContextText') }
    <a className="webex-more-info" target="_blank" href="">{ t('errorContext') }</a>
      <span>{ t('or') }</span>
    <a className="webex-more-info" target="_blank" href="#" onClick={downloadAllLogs}>{ t('downloadLog') }</a>
  </p>
  </div>
  ;

  const retryAdminFootNote = 
  <div className="navigation-link">
  <p>
    { t('moreInfoWebex') } {" "}
    <a className="webex-more-info" target="_blank" href="https://www.webex.com/cloud-calling.html">
      { t('clickHere') }
    </a>
    <br/><br/>
    { t('admitConsentWebex') } {" "}
    <a className="grant-consent" target="_blank" href={getAdminConsentUrl()}>{ t('grantPermission') }</a>
  </p> </div>;

  // Render sign in, otherwise children is an option
  // Create a sign in Wrapper for the component so users can have Signed In components.
  if (isLoading) {
    return (
      <div className="authorization-page">
        <LoadingSpinner scale={64} />
      </div>
    );
  }
  // Decide which Error Screen(Missing Admin Consent or Error getting MS Token) to show based on the state
  else if(shouldShowMsftSignIn) {
    return (
      <WebexSignInPage
      signInImage= {<RetrySignInImage/>}
      signInTitle= {showSignInOrRetry ? t('loginUnsuccessfull') : t('webexAccessError')}
      signInMessageStart= {showSignInOrRetry ? t('loginUnsuccessfullText') : t('webexAccessErrorTitle')}
      signInMessageEnd={''}
      signInButtonText={t('loginUnsuccessButtonText')}
      footNote= {showSignInOrRetry ? retryFootNote : retryAdminFootNote}
      signInAction={retryMsftSignIn}
      showSignInOrRetry = {showSignInOrRetry}
      signingIn= {signingIn}
      clientType= {clientType}
    ></WebexSignInPage>
    );
  } 
  else if(shouldShowWebexSignIn) {
    return (
      <WebexSignInPage
        signInImage={showSignInOrRetry ? <RetrySignInImage/> : <WebexSignInImage/>}
        signInTitle={showSignInOrRetry ? t('loginUnsuccessfull') : t('webexLoginRequiredText')}
        signInMessageStart={showSignInOrRetry ? t('loginUnsuccessfullTextStart') : ''}
        signInMessageEnd={showSignInOrRetry ? t('loginUnsuccessfullTextEnd') : ''}
        headerNoteStart = {showSignInOrRetry ? t('') : t('loginRequiredHeaderNoteTextStart')}
        headerNoteEnd= {showSignInOrRetry ? t('') : t('loginRequiredHeaderNoteTextEnd')}
        signInButtonText={showSignInOrRetry ? t('loginUnsuccessButtonText') : t('loginRequiredButtonText')}
        footNote={showSignInOrRetry ? retryFootNote : continueFootNote}
        signInDifferentAccountText={showSignInOrRetry ? t('loginUnsuccessButtonDifferentAccountText') : t('loginDifferentAccounButtonText')}
        signInAction={showSignInOrRetry ? retryWebexSignIn : webexSignIn}
        showSignInOrRetry = {showSignInOrRetry}
        signingIn= {signingIn}
        email = {email}
        clientType= {clientType}
      ></WebexSignInPage>
    );
  } 
  else if (clientType !== null) {
  //   if(getLocalStorageItem(FIRST_TIME_DOWNLOAD)=== null && getLocalStorageItem(SPEED_DIAL_CACHED_DATE)=== null) {
  //     // Decide which Download Webex Screen to show based on the clientType
  //     if(clientType !== 'desktop' && clientType !== 'web') {
  //     return(
  //       <DownloadWebex clientType = {clientType} src='main'></DownloadWebex>
  //     );  
  //   }
  //   else {
  //     return(
  //       <DownloadWebexDesktop clientType = {clientType} src='main'></DownloadWebexDesktop>
  //     );
  //   }
  // }
    if (clientType !== HostClientType.desktop && clientType !== HostClientType.web) {
      if (getLocalStorageItem(FIRST_TIME_DOWNLOAD) === null) {
        return (
                <DownloadWebex clientType={clientType} src='main'></DownloadWebex>
              );
      }
      setLocalStorageItem(FIRST_TIME_DOWNLOAD, true);
      return (
        <MobileLandingPage src='main' clientType={clientType}></MobileLandingPage>
      );
    } else {
      setLocalStorageItem(FIRST_TIME_DOWNLOAD, true);
      return (
        <LandingPage></LandingPage>
      )
    }
  } 
  else {
    return (
      <div className="authorization-page">
          <LoadingSpinner scale={64} />
      </div>
    );
  }
});
