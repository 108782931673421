export interface IMetricsData {
  appName: string;
  appSessionId: string;
  appVersion: string;
  entityId: string;
  sessionId: string;
  userObjectId?: string;
  metricEvent: string;
  loginHint?: string;
  hostClientType: string;
  tid?: string;
  key?: string;
  integrationName: string;
  messageId?: string;
  responseStatus?: string;
  callingBackend: string;
  contentSize?: string;
  executionTime?: string;
  presenceServerApiLatency?: string;
  presenceServerApiReturnCode?: string;
  voiceMailDuration?: string;
  voicemailListLatency?: string;
  recentCallsListLatency?: string;
  voiceMailContentErrorResponseCode?: string;
  callForwardSettingsLatency?: string;
  callForwardSettingsApiReturnCode?: string;
  voicemailSettingsLatency?:string;
  voicemailSettingsApiReturnCode?: string;
  callMadeSection?: string;
  activeTab?: string;
  callType?: string;
  orgName?: string;
  downloadRedirectSection?: string;
  phoneServiceStatus?: string;
  callingDockStatus?: string;
  e911Status?: string;
  dataTransferSubscriptionStatus?: string;
  dataTransferErrorStatus?: string;
}
export interface IUserData {
  metricEvent: string;
  messageId?: string;
  responseStatus?: string;
  contentSize?: string;
  executionTime?: string;
  client_timestamp?: string;
  trackingId?: string;
  key?: string;
  value?: string;
  presenceServerApiLatency?: string;
  presenceServerApiReturnCode?: string;
  voiceMailDuration?: string;
  voicemailListLatency?: string;
  recentCallsListLatency?: string;
  voiceMailContentErrorResponseCode?: string;
  callForwardSettingsLatency?: string;
  callForwardSettingsApiReturnCode?: string;
  voicemailSettingsLatency?:string;
  voicemailSettingsApiReturnCode?: string;
  callMadeSection?: string;
  activeTab?: string;
  callType?: string;
  orgName?: string;
  downloadRedirectSection?: string;
  phoneServiceStatus?: string;
  callingDockStatus?: string;
  e911Status?: string;
  dataTransferSubscriptionStatus?: string;
  dataTransferErrorStatus?: string;
}

export enum METRIC {
  BUSINESS = "business",
  OPERATIONAL = "operational",
  BEHAVIORAL = "behavioral",
}

export enum INTEGRATION {
  MSTEAMS = "msteams",
}

export enum APP {
  WEBEXTEAMS = "webexteams",
}

export enum CALLING_BACKEND {
  WEBEX_USER = "webex_userCallingBackend",
}

export enum CALL_FORWARDING {
    CallForwardSettingsLatency = "callForwardSettingsLatency-metric",
    VoicemailSettingsLatency = "voicemailSettingsLatency-metric",
    CallForwardSettingsReturnCode = "callForwardSettingsReturnCode-metric",
    VoicemailSettingsMetricReturnCode = "voicemailSettingsReturnCode-metric",
    CallForwardSettingsFailure = "callForwardSettingsFailure-metric",
    VoicemailSettingsFailure = "voicemailSettingsFailure-metric",
}

export interface IMetricsAdaptor {}
