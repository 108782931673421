import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AlertBannerNext as AlertBanner, ButtonPill } from '@momentum-ui/react-collaboration';
import { useTranslation } from 'react-i18next';
import './PhoneServicesAlert.scss';
import { EventType, eventEmitter } from '../../packages/widgets/src/utils/eventEmitter';
import useEventListeners from '../../packages/widgets/src/hooks/useEventListeners';
import { formatError, getLocalStorageItem, Logger } from '../../packages/logger/src';
import { getPhoneServicesData } from '../../packages/widgets/src/services/WebexEventListener';
import { submitMetrics } from "../../packages/widgets/src/services/WebexMetricsService";
import { DISPATCH_STORAGE_EVENTS, IntegrationPhoneServicesResponse, PHONE_SERVICES_STATUS_LABEL } from '../../packages/adapter-interfaces/src';
import { v4 as uuid } from 'uuid';
import { PHONE_SERVICE_STATUS_FOR_METRICS, PHONE_SERVICES_METRIC_EVENT } from '../../packages/widgets/src/utils/MetricUtils';

export const PhoneServicesAlert: React.FC = () => {

  const { t } = useTranslation();
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [showReconnecting, setShowReconnecting] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [showOpenWebex, setShowOpenWebex] = useState<boolean>(false);
  // const {refreshPhoneServicesEventData, phoneServicesEventData  } = useEventListeners();
  const [phoneServiceData, setPhoneServiceData] = useState<IntegrationPhoneServicesResponse | null>(null);

  const handleOpenWebexButtonClick = () => {
    setShowWarning(true);
    setShowOpenWebex(false)
  }

  const handleSignInButtonClick = () => {
    const deviceUrl = window?.webex?.internal?.device?.getId();
    const webex_crossLaunchToken = getLocalStorageItem<string>("webex_crossLaunchToken");
    const requestId = uuid();
    const webexUrl = `webex://integration?cmd=signinphoneservices&requestId=${requestId}&deviceUrl=${deviceUrl}&source=MSTeams&jws=${webex_crossLaunchToken})`;
    const callUrl = new URL(webexUrl);
    window.open(callUrl.href);
    setShowWarning(false);
    setShowReconnecting(true);
  };

  const handleCheckPhoneServiceStatus = useCallback(async () => {
    try {
      const response = await getPhoneServicesData();
      Logger.info(`calling handleCheckPhoneServiceStatus ${response?.data?.eventType}`);
    } catch (error) {
      Logger.error(`Error fetching phone service status: '${formatError(error)}'.`);
    }
  }, []); // No dependencies, so it's memoized

  // eventEmitter.subscribe(EventType.INTEGRATION_PHONE_SERVICES_EVENT_TYPE, 'integrationPhoneServices', () => {
  //   handleCheckPhoneServiceStatus()
  // });

 // Function to handle the storage event and update the state
 const handlePhoneServicesStorageEvent = () => {
  const data = getLocalStorageItem<IntegrationPhoneServicesResponse>(DISPATCH_STORAGE_EVENTS.STORAGE_PHONE_SERVICES_DATA);
  if (data) {
      const phoneServicesEventData = data?.data?.payload?.status;
      Logger.info(
        `Phone Services event status: ${phoneServicesEventData}`
      );

      if (phoneServicesEventData === PHONE_SERVICES_STATUS_LABEL.CONNECTED) {
        setShowSuccess(true);
        setShowWarning(false);
        setShowReconnecting(false);
        Logger.info('Phone services are connected');
        submitMetrics(
          {
            metricEvent: PHONE_SERVICES_METRIC_EVENT,
            phoneServiceStatus: PHONE_SERVICE_STATUS_FOR_METRICS.CONNECTED
            
          }
        );
       

        // const webexUrl = `webex://integration?cmd=ack&eventId=${phoneServicesEventData.id}&source=MSTeams`;
        // const callUrl = new URL(webexUrl);
        // window.open(callUrl.href);

        // Set showSuccess to false after 5 seconds
        const timer = setTimeout(() => {
          setShowSuccess(false);
          Logger.info('time to hide the success screen of phone services')
        }, 6000);

        // Cleanup the timer when component unmounts or when phoneServicesEventData changes
        return () => clearTimeout(timer);
      } else if(phoneServicesEventData === PHONE_SERVICES_STATUS_LABEL.DISCONNECTED) {
        setShowWarning(true);
        setShowSuccess(false);
        setShowReconnecting(false);
        submitMetrics(
          {
            metricEvent: PHONE_SERVICES_METRIC_EVENT,
            phoneServiceStatus: PHONE_SERVICE_STATUS_FOR_METRICS.DISCONNECTED
            
          }
        );
        // const webexUrl = `webex://integration?cmd=ack&eventId=${phoneServicesEventData.id}&source=MSTeams`;
        // const callUrl = new URL(webexUrl);
        // window.open(callUrl.href);
        Logger.info('Phone services are disconnected');

      } else if(phoneServicesEventData === PHONE_SERVICES_STATUS_LABEL.CONNECTING) {
        setShowWarning(false);
        setShowSuccess(false);
        setShowReconnecting(true);
        submitMetrics(
          {
            metricEvent: PHONE_SERVICES_METRIC_EVENT,
            phoneServiceStatus: PHONE_SERVICE_STATUS_FOR_METRICS.CONNECTING
            
          }
        );
        // const webexUrl = `webex://integration?cmd=ack&eventId=${phoneServicesEventData.id}&source=MSTeams`;
        // const callUrl = new URL(webexUrl);
        // window.open(callUrl.href);
        Logger.info('Phone services are connecting');

      }
  }
};

useEffect(() => {
  // Fetch initial data from localStorage
  handlePhoneServicesStorageEvent();

  // Add event listener for the storage event
  window.addEventListener('localstorage:phoneServices', handlePhoneServicesStorageEvent);

  // Cleanup listener on unmount
  return () => {
      window.removeEventListener('localstorage:phoneServices', handlePhoneServicesStorageEvent);
  };
}, []);

useEffect(() => {
  handleCheckPhoneServiceStatus();
}, [handleCheckPhoneServiceStatus]);


  return (
    <div role='status'>
      {showOpenWebex && (
        <div className="mainPhoneDisconnectFeature makingCenterShowOpenWebexBanner" role='status'>
          <AlertBanner
            color="warning"
            image={
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                  <path d="M15.081 11.8753L9.58251 2.37379C9.47285 2.18381 9.31512 2.02604 9.12517 1.91632C8.93522 1.80661 8.71974 1.74883 8.50038 1.74878C8.28102 1.74873 8.06551 1.80641 7.87551 1.91604C7.68551 2.02566 7.5277 2.18336 7.41796 2.37329L1.91796 11.8752C1.80825 12.0653 1.75049 12.2808 1.75049 12.5002C1.75049 12.7197 1.80824 12.9352 1.91795 13.1252C2.02766 13.3152 2.18545 13.473 2.37547 13.5828C2.56549 13.6925 2.78104 13.7502 3.00046 13.7502H13.999C14.2184 13.7502 14.4339 13.6924 14.6239 13.5827C14.8139 13.473 14.9717 13.3151 15.0813 13.1251C15.191 12.9351 15.2487 12.7195 15.2486 12.5001C15.2486 12.2807 15.1908 12.0652 15.081 11.8753ZM7.50012 4.0001C7.50012 3.44781 7.94784 3.0001 8.50012 3.0001C9.05241 3.0001 9.50012 3.44781 9.50012 4.0001V9.0001C9.50012 9.55238 9.05241 10.0001 8.50012 10.0001C7.94784 10.0001 7.50012 9.55238 7.50012 9.0001V4.0001ZM8.50012 13.0001C7.94784 13.0001 7.50012 12.5524 7.50012 12.0001C7.50012 11.4478 7.94784 11.0001 8.50012 11.0001C9.05241 11.0001 9.50012 11.4478 9.50012 12.0001C9.50012 12.5524 9.05241 13.0001 8.50012 13.0001Z" fill="var(--mds-color-theme-text-warning-normal)" />
                </svg>
              </div>
            }
            isCentered
            isGrown
            size="default"
            label={t("phoneServices.openWebexAlertMessage")}
            buttons={[
              <div>
                <ButtonPill
                  ghost
                  outline
                  size={28}
                  onPress={handleOpenWebexButtonClick}
                  key="0"
                  aria-label={t("phoneServices.openWebexButtonLabel")}
                  data-test="breakout-countdown-banner-edit"
                >
                  {t("phoneServices.openWebexButtonLabel")}
                </ButtonPill>
              </div>

            ]}
          />
        </div>
      )}
      {showWarning && (
        <div className="mainPhoneDisconnectFeature makingCenterShowSignInBanner" role='status'>
          <AlertBanner
            aria-live={t('phoneServices.disconnectedAlertMessage')}
            color="warning"
            image={
              <div><svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                <path d="M15.081 11.8753L9.58251 2.37379C9.47285 2.18381 9.31512 2.02604 9.12517 1.91632C8.93522 1.80661 8.71974 1.74883 8.50038 1.74878C8.28102 1.74873 8.06551 1.80641 7.87551 1.91604C7.68551 2.02566 7.5277 2.18336 7.41796 2.37329L1.91796 11.8752C1.80825 12.0653 1.75049 12.2808 1.75049 12.5002C1.75049 12.7197 1.80824 12.9352 1.91795 13.1252C2.02766 13.3152 2.18545 13.473 2.37547 13.5828C2.56549 13.6925 2.78104 13.7502 3.00046 13.7502H13.999C14.2184 13.7502 14.4339 13.6924 14.6239 13.5827C14.8139 13.473 14.9717 13.3151 15.0813 13.1251C15.191 12.9351 15.2487 12.7195 15.2486 12.5001C15.2486 12.2807 15.1908 12.0652 15.081 11.8753ZM7.50012 4.0001C7.50012 3.44781 7.94784 3.0001 8.50012 3.0001C9.05241 3.0001 9.50012 3.44781 9.50012 4.0001V9.0001C9.50012 9.55238 9.05241 10.0001 8.50012 10.0001C7.94784 10.0001 7.50012 9.55238 7.50012 9.0001V4.0001ZM8.50012 13.0001C7.94784 13.0001 7.50012 12.5524 7.50012 12.0001C7.50012 11.4478 7.94784 11.0001 8.50012 11.0001C9.05241 11.0001 9.50012 11.4478 9.50012 12.0001C9.50012 12.5524 9.05241 13.0001 8.50012 13.0001Z" fill="var(--mds-color-theme-text-warning-normal)" />
              </svg></div>
            }
            isCentered
            isGrown
            size="default"
            label={t("phoneServices.disconnectedAlertMessage")}
            buttons={[
              <div>
                <ButtonPill
                  ghost
                  outline
                  size={28}
                  onPress={handleSignInButtonClick}
                  key="0"
                  aria-label={t('signIntoPhoneServices')}
                  data-test="breakout-countdown-banner-edit"
                >
                  {t('signInText')}
                </ButtonPill>
              </div>

            ]}
          />
        </div>
      )}
      {showReconnecting && (
        <div className="mainPhoneDisconnectFeature reconnectingBackGroundColor" role='status'>
          <AlertBanner
            color="default"
            aria-live={t('phoneServices.connectingAriaLabel')}
            image={
              <div className='phoneDisconnectFeatureLoading'>
                <div className="md-loading-spinner-wrapper" data-testid="loading-icon">
                  <div className="md-icon-wrapper">
                    <svg xmlns="http://www.w3.org/2000/svg"
                      width="100%" height="100%" data-test="spinner" fill="currentColor" viewBox="0, 0, 32, 32" data-scale="28" data-autoscale="false">
                      <path d="M16 2.25A13.75 13.75 0 1 0 29.75 16 13.765 13.765 0 0 0 16 2.25Zm0 26A12.25 12.25 0 1 1 28.25 16 12.263 12.263 0 0 1 16 28.25Z">
                      </path>
                    </svg>
                  </div>
                  <div className="md-icon-wrapper md-loading-spinner-arch">
                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" data-test="spinner-partial" fill="currentColor" viewBox="0, 0, 32, 32" data-scale="28" data-autoscale="false">
                      <path d="M15.25 3a.75.75 0 0 0 .75.75A12.264 12.264 0 0 1 28.25 16a.75.75 0 1 0 1.5 0A13.765 13.765 0 0 0 16 2.25a.75.75 0 0 0-.75.75Z">
                      </path>
                    </svg>
                  </div>
                </div>
              </div>
            }
            isCentered
            isGrown
            size="default"
            // aria-label={t("phoneServices.connectingAriaLabel")}
            label={t("phoneServices.connectingLabel")}
          />
        </div>
      )}

      {showSuccess && (
        <div className="mainPhoneDisconnectFeature phoneServicesSucessBanner" role='status'>
          <AlertBanner
            aria-live={t('phoneServices.reconnectedAlertMessage')}
            color="success"
            image={
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                  <path d="M15.25 8C15.25 11.7279 12.2279 14.75 8.5 14.75C4.77208 14.75 1.75 11.7279 1.75 8C1.75 4.27208 4.77208 1.25 8.5 1.25C12.2279 1.25 15.25 4.27208 15.25 8ZM11.8584 4.90616L7.24972 9.51486L5.12523 7.39037C4.67891 6.94405 3.9553 6.94405 3.50898 7.39037C3.06267 7.83668 3.06267 8.5603 3.50898 9.00661L5.93335 11.431C5.94466 11.4423 5.95615 11.4533 5.96781 11.464L6.44229 11.9385C6.88861 12.3848 7.61222 12.3848 8.05854 11.9385L13.4747 6.52241C13.921 6.07609 13.921 5.35248 13.4747 4.90616C13.0283 4.45985 12.3047 4.45985 11.8584 4.90616Z" fill="var(--mds-color-theme-text-success-normal)" />
                </svg>
              </div>
            }
            isCentered
            isGrown
            size="default"
            aria-label={t("phoneServices.reconnectedAlertMessage")}
            label={t("phoneServices.reconnectedAlertMessage")}
          />
        </div>
      )}
    </div>
  );
};

